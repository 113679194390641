export default {
	"Membership": "Status",
	"MEMBERSHIP": "SUBSCRIPTION",
	"BRONZE": "BASIC",
	"after_here": "",

	"/favorite/my": "/favourite/my",
	"/favorite/others": "/favourite/others",

	"Province": "Location",
	"Hair Color": "Hair Colour",
	"Eye Color": "Eye Colour",

	"Favorites": "Favourites",
	"My Favorites": "My Favourites",
	"Add to Favorites": "Add to Favourites",
	"Has added you to favorites": "Has added you to favourites",
	"Nudes not allowed in this category.": "Naked Pictures not allowed in this category.",

	"Favorite added successfully": "Favourite added successfully",
	"Favorite deleted successfully": "Favourite deleted successfully",
	"Make yourself visible to other users by adding them to favorites": "Make yourself visible to other users by adding them to favourites",
	"Choose your favorite": "Choose your favourite",

	"2Mb or lower.": "2MB or lower.",
	"2mb or lower": "2MB or lower",

	"CLICK TO ACTIVATE - desktop": "CLICK TO ACTIVATE",

	"logout_confirm_title": "Log out",

	"rg_terms": "Terms and Conditions",
	"rg_privacy": "Privacy policy",
	"rg_cookies": "Cookies",

	"No favorites added": "No favourites added",

    "We have sent you an email to:": "We have sent you an email to:",
    "Please confirm by clicking the \"delete account\"  button in that email and your account will be permanently deleted from our system.": "Please confirm by clicking the \"delete account\"  button in that email and your account will be permanently deleted from our system.",
    "DELETE ACCOUNT": "DELETE ACCOUNT"

}
