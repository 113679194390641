export default {
	"Already a member?": "Ya eres Miembro?",
	"Login": "ENTRAR",
	"Log out": "Salir",
	"Your current status": "Tu estado actual",
	"BASIC": "BÁSICO",
	"Basic": "Básico",
	"Bronze": "Básico",
	"Elite": "Élite",
	"VIP": "VIP",
	"Join Us": "Entra ahora",
	"Find people near you": "Encuentra gente cerca de ti",
	"Join now": "Unirme gratis",
	"I am a": "Say",
	"Email": "Correo",
	"It will not be published": "No será publicado",
	"E-mail address": "Introduce Email Real",
	"SEARCH NOW": "UNIRME GRATIS",
	"I confirm that I am over 18 years old": "Confirmo que soy mayor de 18 años",
	"Do not just think about it! Discover the hottest dating site in Australia. Every day thousands of people like you join us.": "No lo pienses más y entra al fenómeno social del momento. Cada día miles de personas como tú se unen a nosotros.",
	"Information": "Información",
	"All rights reserved.": "Todos los derechos reservados.",
	"SUBSCRIPTION": "SUSCRIPCIÓN",
	"Other": "Otros asuntos",
	"All fields are required": "Se requieren todos los campos",
	"The fields marked in Red are required. Please fill them to continue.": "Los campos marcados en rojo son obligatorios. Por favor llénelos para continuar.",
	"If you did not find a suitable answer please contact us": "Si no encontraste el tema apropiado, por favor escríbenos",
	"Will help us to identify you": "Nos ayudará a identificarte",
	"Invalid Username": "Apodo inválido",
	"Subject": "Tema",
	"Tell us how can we help you": "Dinos como podemos ayudarte",
	"Please enter subject": "Por favor introduce tema",
	"Please enter at least 10 characters": "Por favor introduce mínimo 10 caracteres",
	"Connected to your account": "Conectado a tu cuenta",
	"Repeat Email": "Repite Correo",
	"Emails did not match": "Los Correo no coinciden",
	"Message": "Mensaje",
	"No Recent Activity": "No hay Actividad Reciente",

	//route labels
	"Home": "Inicio",
	"Contact": "Contacto",
	"Support": "Soporte",
	"Affiliates": "Afiliados",
	"Privacy": "Privacidad",
	"Terms": "Términos",

	//routes uri
	"/login": "/iniciar-sesion",
	"/contact": "/contacto",
	"/support": "/soporte",
	"/affiliates": "/programa-de-afiliacion",
	"/privacy": "/privacidad",
	"/terms-and-conditions": "/terminos-y-condiciones-de-uso-generales",
	"/cookie-policy": "/politica-de-cookies",
	"/subscription/exit": "/subscripcion/exito",
	"/purchase-confirmation": "/confirmacion-compra",
	"/page/:page_name": "/pagina/:page_name",

	"/dashboard": "/inicio",
	"/mobile-dashboard": "/panel-de-control-movil",
	"/activate-profile/:token": "/activar-perfil/:token",
	"/edit-profile": "/mi-perfil",
	"/profile/:name": "/perfil/:name",
	"/my-picture": "/mis-fotos",
	"/messages/inbox": "/mensajes/inbox",
	"/messages/trash": "/mensajes/papelera",
	"/messages/send/:name": "/mensajes/enviar/:name",
	"/visitor": "/quien-me-ha-visto",
	"/favorite/my": "/mis-favoritos/mi",
	"/favorite/others": "/mis-favoritos/otros",
	"/search": "/buscar-perfiles",
	"/search-result": "/resultado-busqueda",
	"/settings": "/ajustes",
	"/validate-new-email-address/:token": "/verificar-nueva-direccion-de-email/:token",
	"/subscription": "/subscripcion",
	"/credits": "/creditos",
	"/elite": "/elite",
	"/faq": "/preguntas-y-respuestas",
	"/delete-account": "/cuenta-eliminada",

	"/exclusive-promotion": "/promocion-exclusiva",
	"/account-deleted": "/cuenta-borrad",
	"/logged-out": "/sesion-cerrada",

	//login
	"Login": "ENTRAR",
	"Enter username or Email": "Introduce nombre de usuario o email",
	"Enter Password": "Introduce Contraseña",
	"Username": "Apodo",
	"Nickname": "Apodo",
	"Password": "Contraseña",
	"I forgot my password": "Olvidé mi contraseña",
	"Password Reminder": "Recordatorio Contraseña",
	"Enter you email": "Introduce tu correo",
	"Your email address": "Tu correo electrónico",
	"Don't have an account?": "¿No tienes cuenta aún?",
	"REGISTER": "UNIRME",
	"Password sent to your email": "Contraseña enviada a tu correo electrónico",
	"This account has not been validated yet": "Esta cuenta aún no ha sido activada",
	"LOGIN USING YOUR CREDENTIALS": "INTRODUCE TUS DATOS",
	"FORGOT YOUR PASSWORD?": "¿OLVIDASTE TU CONTRASEÑA?",
	"Login failed": "fallo de conexión",
	"Username or password is incorrect": "Nombre de usuario o contraseña incorrecta",

	"We're sorry!": "¡Lo sentimos!",
	//registration
	"You're almost done!": "¡Casi has acabado!",
	"Please fill your additional account information": "Por favor rellena la información adicional de tu cuenta",
	"Just one step left!": "Solo un paso más!",
	"Let us learn more about you. Fill in your personal data": "Déjanos conocerte mejor. Rellena tus datos personales",
	"Activate your account now.": "¡Activa tu cuenta ya!",
	"Activate your account now": "¡Activa tu cuenta ya!",
	"Please confirm your e-mail address": "Por favor confirma tu dirección de correo",
	"Please fill in the following form to join {site_name} for free": "Por favor rellena el siguiente formulario para unirte gratuitamente a {site_name}",
	"CONTINUE": "CONTINUAR",

	"By clicking on the continue button I agree with the": "Haciendo clic en \“Continuar\” acepto los",
	"terms": "términos",
	"and cookies": "y cookies",

	"This site is protected by reCaptcha and the Google": "Este sitio está protegido por reCaptcha y se aplican la",
	"Privacy Policy": "política de privacidad",
	"and": "y",
	"Terms of Service": "términos del servicio",
	"apply.": "de Google.",

	"Personal description": "Descripción personal",
	"optional": "opcional",
	"FINALIZE": "FINALIZAR",
	"We have just sent you a confirmation e-mail at": "Te hemos enviado un correo de confirmación a",
	"To start using our website, please click on the activation link in this email.": "Para empezar a usar nuestra web, pincha en el link de activación de este correo.",
	"Click here to verify your email": "Clica aquí para verificar tu correo",
	"CLICK TO ACTIVATE": "CLIC PARA ACTIVAR",
	"CLICK TO ACTIVATE - desktop": "CLIC PARA ACTIVAR",
	"VERIFY MY EMAIL": "VALIDAR MI EMAIL",
	"Not received any email yet?": "Aún no has recibido ningún correo?",
	"Please check your": "Por favor revisa tu bandeja de",
	"spam": "correo no deseado",
	"box and wait at least 10 minutes or re-send activation": "y espera al menos 10 minutos o reenvía la activación.",
	"Enter Nickname": "Introduce Apodo",
	"Enter Your Name": "Introduce Tu Nombre",
	"Password Required": "Contraseña requerida",
	"Enter valid Email": "Inserta correo valido",
	"Please enter at least 5 characters": "Por favor introduce 5 caracteres",
	"Enter strong password": "Introduce contraseña fuerte",
	"Email not available": "Email no disponible",
	"Nickname not available": "Apodo no disponible",
	"Maximum 4 number allowed": "Max. permitido 4 cifras",
	"Name should not contain any space, number or special character": "El nombre no debe contener ningún espacio, número o caracteres especiales",
	"Nickname should not contain any space or special characters": "El sobrenombre no debe contener ningún espacio ni caracteres especiales",
	"Your activation mail has been sent": "Tu correo de activación ha sido enviado",
	"Complete your profile in a minute and start contacting people": "Completa tu perfil en un minuto y contacta con gente",
	"Complete your profile": "Completa tu perfil",
	"Create Profile": "Crear Perfil",
	"Your description": "Tu descripción",
	"Confirm your Email": "Confirma tu correo",
	"We have sent you an email.": "Te hemos enviado un correo.",
	"Check your email to complete your registration": "Revisa tu bandeja de entrada para completar el registro",
	"and be able to speak with other users.": "y poder hablar con otros usuarios.",
	"If you can not find it look it up in the spam or": "Si no puedes encontrarlo, revisa la bandeja de correo no deseado o",
	"If you have not received it select one of the following options": "Si no lo has recibido, selecciona una de las siguientes opciones",
	"junk folder": "spam",
	"Change Email": "Cambia el Correo",
	"Resend Email": "Reenvía el Correo",
	"Resend": "Reenvíar",
	"New Email": "Nuevo Correo",
	"Your activation key is invalid": "Tu clave de activación es invalida",
	"Please include an '@' in the email address. '{email}' doesn't have an '@'": "Por favor, incluye una '@' en el correo electrónico. A '{email}' le falta la '@'.",

	//public pages
	"privacy": "privacidad",
	"terms-and-conditions": "terminos-y-condiciones-de-uso-generales",
	"about-us": "sobre-nosotros",

	//forms
	"Name": "Nombre",
	"Marital Status": "Estatus",
	"Date of Birth": "Fecha de nacimiento",
	"Country": "País",
	"Location": "Ubicación",
	"Province": "Provincia",
	"City": "Población",
	"Save Changes": "Guardar Cambios",
	"Select from list": "Elige de la lista",
	"Day": "Dia",
	"Month": "Mes",
	"month": "Mes",
	"Year": "Año",
	"Prefer not to say": "Prefiero no decirlo",
	"Only profiles with photo": "Sólo perfiles con foto",
	"This field is required": "este campo es requerido",
	"Please update your profile": "Actualiza tu perfil",
	"Profile successfully updated": "Perfil actualizado correctamente",
	"The fields marked in red are required. Please fill them to continue.": "Los campos marcados en rojo son obligatorios. Por favor llénelos para continuar.",
	"Characters left": "Carácteres restantes",
	"Image attached": "Imagen adjunta",
	"The message field is required.": "El campo mensaje es requerido.",
	"WITH PICTURE": "CON FOTO",
	"all age groups": "SELECCIONA EDAD",
	"EDIT DESCRIPTION": "EDITAR DESCRIPCIÓN",
	"DESCRIPTION": "DESCRIPCIÓN",
	"FEATURING": "PERSONALIDAD",
	"Please Select": "Por favor Selecciona",
	"Emails do not match": "Los Correo no coinciden",
	"Your request was succesfully sent!": "¡Tu solicitud se envió correctamente!",
	"Or send us a message directly": "O envíanos un email directamente",

	//user pages
	"My Profile": "Mi Perfil",
	"Add Photos": "Añadir Fotos",
	"Messages": "Mensajes",
	"Favorites": "Favoritos",
	"Search": "Búsqueda",
	"Settings": "Ajustes",
	"Visits": "Visitas",
	"Likes": "Favorito de otros",
	"Membership": "Estado",
	"Vip Members": "Top Usuarios",

	"Account activation successful": "ACTIVACIÓN DE CUENTA EXITOSA",
	"My chats": "Mis chats",

	//user home
	"Quick Search": "Búsqueda Rápida",
	"More Options": "Más Opciones",
	"I am a": "Soy",
	"Looking for": "Buscando",
	"Age": "Edad",
	"Search for": "Buscar",
	"All age groups": "Todos los grupos de edad",
	"Popular Members": "Miembros Populares",
	"New Members": "Nuevos Miembros",
	"Find a Match": "Realizar búsqueda",
	"Recent Activity": "Actividad Reciente",
	"Add Photo": "Añadir Foto",
	"Back": "Volver",
	"Become Elite": "MEJORAR",
	"Upgrade": "MEJORAR",

	"likes": "FLECHAZO",
	"EDIT MY PROFILE": "EDITAR MI PERFIL",
	"CONFIGURATION": "CONFIGURACIÓN",
	"ONLINE SUPPORT": "SOPORTE EN LÍNEA",

	//user message
	"Trash": "Papelera",
	"Delete Selected": "Borrado Seleccionado",
	"No Messages Available": "No hay Mensajes disponibles",
	"No Messages selected": "No hay mensajes seleccionados",
	"Back to inbox": "Volver a la bandeja de entrada",
	"Back to messages": "Volver a mensajes",
	"Select all": "Seleccionar todos",
	"Deselect all": "Deseleccionar todos",
	"Next Page": "Próxima página",
	"Prev Page": "Página Previa",
	"Type your message here": "Escribe tu mensaje",
	"Send message": "Enviar mensaje",
	"Send Icebreaker": "Enviar Rompehielos",
	"VIEW PROFILE": "VER PERFIL",
	"DELETE": "BORRAR",
	"Chat with": "Chatea con",
	"Chat": "Chatea",

	//user favorites
	"My Favorites": "Mis favoritos",
	"No likes": "No hay favoritos",
	"Favorite added successfully": "Favorito añadido con éxito",
	"Favorite deleted successfully": "Favorito borrado con éxito",
	"Visit Profile": "Visita Perfil",

	//user visits
	"My Profile Visitors": "Visitas a Mi Perfil",
	"No Visitors": "Sin Visitas",

	//user photos
	"My pictures": "Mis fotos",
	"See my public profile": "Ver mi perfil público",
	"Profile photo": "Foto de Perfil",
	"Profile Picture": "Foto de Perfil",
	"2MB or lower.": "2MB o menor.",
	"2MB or lower": "2MB o menor",
	"Nudes not allowed in this category.": "Desnudos NO permitidos en esta categoría.",
	"PHOTO ALBUM": "ÁLBUM DE FOTOS",
	"Allowed images 2 megabytes or less": "Permitidas imágenes de 2 megabytes o menos",
	"Add picture": "Añadir foto",
	"Main reasons for rejection": "Principales motivos de rechazo",
	"Are you sure?": "¿Estás seguro?",
	"Are you sure you want to delete this image?": "¿Estás seguro de que quieres eliminar esta imagen?",
	"Close": "Cerrar",
	"Confirm": "Confirmar",
	"Attaching image": "Adjuntando imagen",
	"The message must be at least 2 characters.": "Este mensaje tiene que tener al menos 2 caracteres.",
	"Deleting...": "Borrando...",
	"Pictures of underage people": "Fotos de menores",
	"Personal data is visible": "Con datos personales",
	"Fake or stolen pictures": "Fotos falsas o robadas",
	"Different gender to profile": "Distinto género al perfil",
	"Group pictures": "Fotos de grupo",
	"Weapons, drugs, violence": "Armas, drogas, violencia",
	"No people visible": "No hay personas",
	"Info": "Información",
	"We are reviewing your images. When they pass the verification process they will be shown to the other users.": "Estamos revisando tus imágenes. Cuando pasen el proceso de validación, serán mostradas a otros usuarios.",

	//user profiles
	"Profile": "Perfil de",
	"yrs": "Años",
	"years": "años",
	"yr from": "Años",
	"Physique": "Constitución",
	"Photos": "Fotos",
	"No Photos": "Sin Fotos",
	"About Me": "Sobre mi",
	"Characteristics": "Características",
	"Prefers": "Preferencias",
	"Relationship": "Relación",
	"Shape": "Cuerpo",

	//user edit profile
	"DESCRIBE YOURSELF": "DESCRÍBETE",
	"GENERAL": "GENERAL",
	"(Will be verified)": "(Será verificado manualmente)",
	"Sex": "Sexo",
	"Seeking": "Buscando",
	"Finish your profile": "Acaba tu perfil",
	"Welcome! As your profile is new, we will give maximum visibility once you complete it.": "¡Bienvenido! Como tu perfil es nuevo, si lo completas tendrá visibilidad máxima.",

	//search profile
	"Advanced Search": "Búsqueda Avanzada",
	"OPEN TO": "ABIERTO A",
	"PREFERENCES": "PREFERENCIAS",
	"CHARACTER": "CARÁCTER",
	"Search Result": "Resultados de la Búsqueda",
	"No Member found": "Miembro No Encontrado",
	"Change Search": "Cambiar búsqueda",
	"Hair Color": "Pelo",
	"Eye Color": "Ojos",
	"Body Type": "Cuerpo",
	"Height": "Estatura",
	"Weight": "Peso",
	"Smoker": "Fumador",
	"Ethnicity": "Etnia",
	"From": "de",
	"To": "A",

	//settings
	"Change E-mail address": "Cambiar Dirección De Correo Electrónico",
	"Your email address has been successfully updated": "Tu dirección de correo electrónico ha sido actualizada correctamente",
	"Password": "Contraseña",
	"Account Status": "Estado Cuenta",
	"Cancel": "Cancelar",
	"Delete Account": "Borrar Cuenta",
	"Notifications": "Notificaciones",
	"Receive all notifications individually": "Recibir todas las notificaciones individualmente",
	"Receive one daily email will all my notifications": "Recibirlas agrupadas en un email diario",
	"Receive a weekly email with all my notifications": "Recibirlas agrupadas en un email semanal",
	"I don\'t want to receive any notifications": "No quiero recibir notificaciones",
	"(we will send you a verification email)": "(Te enviaremos un email de verificación)",
	"Current Password": "Contraseña Actual",
	"New Password": "Nueva Contraseña",
	"Enter valid email": "Inserta correo valido",
	"Repeat New Password": "Repite Nueva Contraseña",
	"Save Settings": "Cambiar Ajustes",
	"Passwords should match": "La contrase a debe coincidir",
	"Password length should be minimum 5 characters": "La longitud de la contraseña debe ser de 5 caracteres como mínimo",
	"Attention: Your new email address must be verified by clicking on the link that we just sent you.": "Atención: Tu nueva dirección de correo electrónico ha de ser verificada pinchando en el vínculo que te acabamos de enviar.",
	"I want to receive notifications by email": "Quiero recibir notificaciones en mi correo",
	"Status": "Status",
	"Renewal": "Renovar",
	"Auto renewal Canceled": "Autorrenuevo cancelado",
	"Delete profile": "Eliminar perfil",
	"Your account has been deleted. You will be redirected.": "Tu cuenta ha sido borrada. Serás redirigido.",
	"Successfully updated": "Configuración correctamente actualizada",
	"Current account status": "Estado Cuenta Actual",
	"Are you sure you want to cancel your subscription?": "¿Estás seguro de querer cancelar tu suscripción?",
	"Your subscription has been successfully canceled and will not be auto-renewed": "Tu suscripción ha sido cancelada con éxito y no se autorrenovará",
	"Due to technical reasons, cancellation of the renewal of the trial must be made after 8 hours of the acquisition. For any other subscription, the cancellation must be done at least 8 hours after acquisition and before 48 hours of its expiration.": "Por motivos técnicos, la cancelación del renuevo del abono de prueba, deberá realizarse pasadas 8 s de la adquisición. Para cualquier otro tipo de abono, la cancelación deberá realizarse pasadas 8 s de la adquisición y antes de 48 s de su vencimiento.",
	"Profile deleted. Redirecting...": "Perfil eliminado. Redireccionando ...",
	"*Active subscriptions deleted this way will not be reimbursed": "*Los bonos en activo eliminados de esta manera no serán reembolsados.",
	"Are you sure you want to delete * your account? Your information will be permanently erased.": "¿Estás seguro que quieres borrar tu Cuenta? Ya no podrás recuperar tu información más tarde",
	"Settings successfully updated": "Configuración actualizada con éxito",
	"Disable Notification": "Desactivar notificaciones",
	"The notifications have been successfully disabled.": "Las notificaciones han sido desactivadas con éxito.",
	"Canceled Auto-Renewal": "Auto-Renuevo Cancelado",

	//subscription
	"Only Vip members can contact more than 5 different users per day.": "Solo los miembros Vip pueden contactar más de 5 usuarios distintos al día.",
	"Upgrade your status": "Mejora tu estado",
	"Enjoy our exclusive benefits and make new contacts": "Disfruta de nuestros beneficios exclusivos y contacta por menos de 0.03€ al día.",
	"Users Online": "Usuarios Conectados",
	"Discreet Billing. {site_name} will not appear in the invoice": "Facturación Discreta. {site_name} no aparecerá en la factura",
	"Month ::: Months": "Mes ::: Meses",
	"3 days trial": "PRUEBA DE 3 DÍAS",
	"Try 3 days": "Elegir Ahora",
	"MEMBERSHIP": "SUSCRIPCIÓN",
	"Select": "Elegir",
	"Send messages for 3 consecutive days": "Envía mensajes 3 días distintos",
	"Only one trial subscription per user": "Solo permitido un abono de prueba por usuario",
	"In order to offer better service without interruptions, all plans will be auto renewed at the cost of one of the following subscriptions based on the pack chosen above.": "La subscripción será automáticamente renovada al coste de una de la siguiente subscripciones basadas en el pack escogido arriba",
	"for": "por",
	"For full access to all details of events, including how to cancel it,": "Para tener acceso completo a todos los detalles del evento, incluyendo cómo cancelarlo,",
	"click here": "haga clic aquí",
	"for terms and conditions,": "Para términos y condiciones,",
	"All profiles are reviewed and moderated by our team.": "Todos los perfiles son revisados ​​y moderados por nuestro equipo.",
	"CURRENT PLAN": "SUSCRIPCIÓN ACTUAL",
	"Basic (no trial bonus) You do not have any active pack at this moment and you are a BASIC user. To increase your chances of connecting with and contacting other users, you will need to upgrade to Elite or VIP status.": "Básica (sin bono de prueba) actualmente no tienes ningun pack activo y tu estado es BÁSICO. Para incrementar considerablemente tus posibilidades de ligar y contactar otros usuarios, necesitarás estado Elite.",
	"MY BENEFITS": "MIS BENEFICIOS",
	"{discount} cheaper": "Ahorra un {discount}",
	"save": "Ahorra",
	"Create my profile": "Crear mi perfil",
	"Read messages": "Leer mensajes",
	"Add to Favorites": "Anadir a Favoritos",
	"Make yourself visible to other users by adding them to favorites": "Date a conocer a otros usuarios haciéndolos tus favoritos",
	"Photo Album": "Álbum de fotos",
	"No fake pictures allowed": "No se permiten fotos falsas",
	"Album pictures": "Fotos de álbum",
	"Show yourself and increase your possibilities": "Muéstrate y aumenta tus posibilidades",
	"Detailed profile search": "Busqueda detallada de perfiles",
	"Find exactly what you are looking for": "Encuentra exactamente lo que buscas",
	"See other user's pics": "Ver fotos de otros usuarios",
	"Choose your favorite": "Elige quien más te guste",
	"Reply to and send messages": "Enviar o responder mensajes",
	"Make direct contact with up to five users per day": "Haz contacto directo hasta con cinco usuarios distintos al día",
	"Be shown first in searches": "Aparecer antes en las búsquedas",
	"Your profile will be featured before the rest of the users": "Tu perfil será destacado sobre el resto de usuarios",
	"Make direct contact with unlimited users": "Haz contacto directo con Usuarios ilimitados",
	"Choose payment method": "Elige el método de pago",
	"LESS THAN": "MENOS DE",
	"PER DAY": "POR DÍA",
	"DISCRETION": "DISCRECIÓN",
	"Our website name will not be disclosed": "Nuestro nombre no saldrá en la factura",
	"Our name will not be disclosed": "Nuestro nombre no saldrá en la factura",
	"100% Anonymous.": "100% Anónimo.",
	"Choose your subscription": "Elige tu suscripción",
	"Recommended": "Recomendado",
	"Most Popular": "Más popular",
	"Secure payments with": "Pagos seguros con",
	"Your message has NOT been sent. You need to upgrade your status in order to send messages.": "Tu mensaje NO ha sido enviado. Mejora tu estado para poder enviar mensajes.",
	"Your purchase has been successful": "Tu compra ha sido realizada con éxito",
	"All subscriptions automatically renew to ensure optimum service. If you want to change the settings you can do it": "Para garantizar un óptimo servicio y un uso sin interrupciones todas las suscripciones son autorrenovables. Si deseas cambiar la configuración puedes hacerlo",
	"here.": "aquí.",
	"after_here": "",

	//ad
	"Become a": "Hazte",
	"PREMIUM MEMBER": "MIEMBRO PREMIUM",
	"And contact": "Y contacta con",
	"more people": "más gente",

	"upgrade": "empezar",
	"Boost": "Mejora",
	"boost": "Mejorar",
	"your profile": "tu perfil",
	"Start chatting and meet more people": "Chatea y conoce gente",
	"And get": "Y recibe",
	"more views": "más visitas",

	"Now +3 extra Credits with each purchase": "Ahora +3 Créditos de regalo con cada compra",
	"Best Seller": "Más vendido",
	"Select your Package": "Elige tu pack",
	"Less than {amount} per credit": "Menos de {amount} por crédito",
	"Less than": "Menos de",
	"per credit": "por crédito",
	"Free credits": "Créditos gratis",
	"per purchase": "con cada compra",
	"Premium Status doesn’t allow free messages.": "El estado Premium no permite enviar mensajes gratis.",
	"Chat now": "Chatea ahora",
	"Get Credits": "Añadir Créditos",
	"Credit": "Crédito",
	"Credits": "Créditos",
	"My credits": "Mis créditos",
	"{count} Credits left": "{count} Créditos disponibles",
	"Your message has not been sent, you need credits to be able to send messages.": "Tu mensaje no ha sido enviado, necesitas créditos para enviar mensajes.",
	"Icebreaker": "Rompehielos",
	"Icebreaker today": "Rompehielos hoy",
	"The cost for sending an Icebreaker is 2 credits. The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "El coste por enviar un Rompehielos es 2 créditos. El Rompehielos solo puede utilizarse para iniciar una conversación o como respuesta a otro rompehielos.",
	"With the acquisition of each package, users make a purchase of a pack of credits that can be used to send messages. The ratio of credits per message is ten to one, that means, the user needs to have at least ten credits in his account to send a message. The cost for sending an Icebreaker is 2 credits.": "Con la adquisición de cada Pack, los usuarios realizan la compra única de un paquete de créditos los cuales pueden ser utilizados para enviar mensajes. El ratio de créditos por mensaje es de diez a uno, es decir, que el usuario necesita tener al menos diez créditos en su cuenta, los cuales serán utilizados para enviar un mensaje. El coste por enviar un Rompehielos es 2 créditos.",

	"Trial pack": "Pack de prueba",
	"Only one trial pack per user allowed": "Solo permitido un pack de prueba por usuario",
	"Used": "Utilizado",

	"Exclusive Promotion": "Promoción Exclusiva",
	"Account Deleted": "Cuenta Borrada",
	"Log out Successful": "Sesión Cerrada",

	"Do you like FREE LIVE SEX?": "Te gusta el SEXO EN DIRECTO GRATUITO?",
	"Enter this code to enjoy a 10% discount:": "Disfruta un 10% de descuento utilizando el código:",
	"Discount redeemable for your first online purchase. Expires in 2 hours.": "Este código es valido por las próximas 6 horas en la primera compra realizada en la web.",

	"This account has been deleted.": "Esta cuenta ha sido borrada.",
	"Our algorithm has found a better option in line with your taste.": "Nuestro algoritmo ha encontrado una opción mejor para tus gustos.",
	"You will be redirected in a few seconds.": "Serás redirigido en breves momentos.",

	"Has added you to favorites": "Te ha añadido a favoritos",
	"Is looking at your profile": "Está mirando tu perfil",
	"See profile": "Ver perfil",
	"See message": "Ver mensaje",

	"I think I am in love": "Creo que me estoy enamorando",
	"Hey! I'm winking at you": "¡Hey! Este guiño es para ti",
	"Tell me more about you. Fancy a chat?": "Cuéntame más de ti. ¿Chateamos?",
	"Some flowers for you!": "¡Unas flores para ti!",
	"Sending you a kiss": "Te mando un beso",
	"Surprise, it\'s me!": "¡Sorpresa, soy yo!",
	"Hi, how are you?": "Hola, ¿Qué tal estás?",
	"Let's get naughty": "Vamos a hacer cositas...",

	"Get 5 times the regular response rate. Premium members benefits:": "Disfruta de cinco veces mas acción. Los beneficios de los miembros premium son:",
	"Appear high up on searches": "Aparecer mas arriba en las búsquedas",
	"Distinctive badge that increases your visibility": "Insignia distintiva para incrementar visibilidad",
	"5 Free Icebreakers a day": "5 Rompehielos gratis al día",
	"In addition to this, a voucher for a 15% Webcam discount": "Además de esto, un bono de descuento del 15% en webcams",

	"The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "El Rompehielos solo puede utilizarse para iniciar una conversación o como respuesta a otro rompehielos.",

	"Succesful purchase": "Compra realizada",
	"Your purchase has been successful.": "Tu compra ha sido efectuada con éxito.",
	"It can take a few minutes to update.": "Puede tomar unos minutos en reflejarse.",
	"You will be redirected.": "Serás redirigido.",

	"Changes can take some minutes to update.": "Los cambios se actualizarán en unos minutos.",

	"Yearly Package": "Pack anual",
	"Package": "Pack",
	"Buy Now": "Comprar Ahora",
	"The Elite status allows you to contact up to 5 different users every day by sending them unlimited messages.": "El estado Élite permite contactar hasta 5 usuarios distintos diariamente enviandoles mensajes ilimitados.",

	"Exclusive offer": "Oferta exclusiva",
	"Welcome": "Bienvenido",
	"Mary from {domain_name} support": "Lorena de soporte {domain_name}",
	"Welcome to {domain_name}. We hope you find what you are looking for.": "Bienvenido a {domain_name}. Esperamos que encuentres lo que estás buscando.",
	"Here are some Pro Tips to be more successful and meet people:": "Aquí tienes algunos Trucos para tener más éxito al conocer gente:",
	"Upload a profile picture to get more connections.": "Sube una foto para tener más interacciones.",
	"Send an icebreaker to get a conversation easily started.": "Envía un rompehielos para empezar una conversación de forma más sencilla.",
	"If you like someone don’t forget to add them to favorites so they will know.": "Si te gusta alguien, no olvides añadirlo a tus favoritos para hacérselo saber.",
	"And of course, if you like somebody send a message and ask for a date!": "Y por supuesto, si te gusta alguien, envía un mensaje para pedirle una cita!",
	"If you need any extra help, please contact our ": "Si necesitas ayuda extra, por favor, contacta a nuestro ",
	"support": "soporte",
	". We will be happy to help you.": ". Estaremos encantados de ayudarte.",
	"Try your luck": "Prueba suerte",
	"64% of our users have a date in the first 2 weeks.": "el 64% de nuestros usuarios tiene una cita en las 2 primeras semanas.",
	"Good Luck!.": "¡Buena suerte!",
	"*This message will be deleted upon closing.": "*Éste mensaje se borrará al cerrarse.",

	"Private picture": "Foto privada",
	"Private pictures will appear blurred out. Only users that have already paid will be able to see them.": "Las fotos privadas apareceran borrosas. Solo podran verlas los usuarios que hayan hecho cualquier pago con anterioridad.",
	"Name change": "Cambiar nombre",
	"If you want to change your name or nickname, please contact our support team and we will do it within 24h.": "Si deseas cambiarte el nombre o el nick, ponte en contacto con nuestro soporte y lo haran en menos de 24h.",
	"See all images": "Ver todas las fotos",
	"Unlock all private photos forever for just 20 credits": "Desbloquea las fotos privadas para siempre por solo 20 créditos",
	"Unlock forever all private pictures": "Desbloquea las fotos privadas para siempre",

	"This email address is not registered, please sign up to log in.": "Este email no está registrado, por favor regístrate para conectarte.",
	"Congratulations, your account is now active.": "Enhorabuena, tu cuenta ha sido activada.",
	"Complete one last step and have fun!": "Completa el último paso y disfruta!",
	"OR": "O",

	"We sent you an email with a link to activate your account valid for the next 12 hours to:": "Te hemos enviado un correo con un vínculo para activar tu cuenta válido por las próximas 12 horas:",
	"If you haven\'t received it in your inbox or ": "Si no lo has recibido en tu bandeja de entrada o ",
	"or you want to modify the email address, click on the button below.": "o quieres modificar tu email, haz clic en el botón de abajo.",
	"We sent you an email to verify your account, the email will be valid for the next 12 hours:": "Te hemos enviado un email para validar tu cuenta válido por las próximas 12 horas:",
	"If you can\'t find it in your inbox or spam, you can edit or resend it": "Si no puedes encontrarlo en tu buzón o Spam, puedes reenviarlo o editarlo",
	"Resend or change email": "Reenviar o modificar correo",
	"Please use the same email you registered.": "Por favor selecciona el mismo email que en el registro.",
	"Connect with Google": "Conectar con Google",
	"Connect with Yahoo": "Registrarme con Yahoo",
	"Connect with Microsoft": "Conectar con Hotmail",
	"Or turn back": "Volver",
	"Complete your registration": "Completa tu registro",

	"It will not be shared": "No será compartido",
	"It will never be shared. 100% Privacy": "No será compartido. 100% privacidad",

	"Unlock all private pictures forever.": "Desbloquea las fotos privadas para siempre.",
	"Unlock": "Desbloquear",

	"You will be redirected in a second": "Serás redirigido en unos segundos",

	"Card payment": "Pago con tarjeta",
	"Bank transfer": "Transferencia Bancaria",
	"Prepaid card": "Tarjeta prepago",
	"SofortBanking": "Transferencia Bancaria",

	"Error": "Error",
	"page not found": "Pagina no encontrada",
	"Back to homepage": "Volver",

	"per month": "al mes",
	"week": "semana",
	"{count} week": "{count} semana",

	"Your profile has been blocked": "Tu perfil ha sido bloqueado",
	"In order to offer the best possible user experience, we block all profiles that haven\'t sent a message within 21 days of registration.": "Con el objeto de ofrecer una mejor experiencia a nuestros usuarios, bloqueamos los perfiles que no han enviado ningún mensaje 21 días después de su creación.",
	"Please acquire a credit package to unlock your profile and restore all its features.": "Para no perder tu perfil y poder utilizar todas las funciones de nuevo necesitas adquirir un pack de créditos.",
	"Restore access to:": "Accede de nuevo a:",
	"All messages": "Todos mensajes",
	"Users search": "Buscar usuarios",
	"Your contacts": "Tus contactos",
	"All favourites": "Todos los favoritos",
	"All pictures": "Todas las fotos",
	"Send Icebreakers": "Enviar rompehielos",
	"Unblock Profile": "Desbloquear Perfil",

	"This website uses its own and third-party cookies and identifiers to improve accessibility and analyse our traffic. If you continue browsing, we consider that you accept its use. For more information visit our": "Este sitio web utiliza cookies e identificadores propios y de terceros para mejorar la accesibilidad y analizar nuestro tráfico. Si continúa navegando, consideramos que acepta su uso. Para más información visite nuestra",
	"Cookies Policy": "Política de Cookies",

	"DATE GUARANTEE": "GARANTIA DE CITA",
	"Take advantage of our Date guarantee": "Aprovecha nuestra garantía de citas",
	"No date? Money Back!": "¿No hay cita?  ¡Dinero de vuelta!",
	"No risk": "Sin riesgo",
	"Guarantee of success": "Garantía de éxito",
	"Terms and Conditions": "Términos y condiciones",

	"Boost your visibility": "Incrementa tu visibilidad",
	"SAVE": "AHORRA",
	"Add 3 months premium": "Añade 3 meses premium",
	"Add {months} months premium": "Añade {months} meses premium",

	"We guarantee you a real date.": "En nuestra web te damos una garantía de cita real.",
	"YES, I WANT A DATE": "SI, QUIERO UNA CITA",

	"Terms and conditions of the date guarantee. In order to fulfill all refund conditions, you must have a fully completed profile for a period of at least six months and your personal description in your profile. Your profile must contain at least one approved picture throughout this period. Furthermore, each week, you must contact at least seven different members located close to you (radius of 100km) within your age range (-5/+5 years). If you fulfill all the conditions listed above and you still were not able to find a date, we will full refund all the payments you made during this time. This guarantee is only valid for the first 12 months following your registration. Good luck!": "Términos y condiciones de la garantía de citas. Para cumplir con todas las condiciones de reembolso, debes tener un perfil completo y una descripción en tu perfil durante un período de al menos seis meses. Tu perfil debe contener al menos una foto de perfil aprobada durante este período. Además, cada semana, debes comunicarte con al menos siete miembros diferentes ubicados cerca de ti (radio de 100 km) dentro de tu rango de edad (-5 / + 5 años). Si cumples con todas las condiciones enumeradas anteriormente y no pudiste encontrar una cita, te reembolsaremos por completo todos los pagos realizados desde tu registro. Esta garantía solo es válida 12 meses después de su registro. ¡Buena suerte!",

	"You have {count} days premium left": "Te quedan {count} días premium",
	"Check our special offer before deleting your account!": "¡Aprovéchate de nuestra oferta especial antes de borrar tu cuenta!",
	"Delete my account": "Borrar mi cuenta",
	"Free": "Gratis",

	"Premium, Elite and VIP packages are auto-renewable, which means they will auto-renew when they expire.": "Los Packs Premium, Elite y VIP son autorrenovables, es decir, se auto renovaran al expirar su duración.",
	"and can confirm that I am 18 years or older.": "y confirmo que soy mayor de 18 años.",

	"COMPLETE YOUR PURCHASE": "COMPLETA TU COMPRA",
	"Payment details": "Detalles de pago",
	"Total": "Total",
	"3-digit number on the back of your credit card": "Número de 3 dígitos en el reverso de su tarjeta de crèdito",
	"MM / YY": "MM / AA",
	"MM": "MM",
	"YY": "AA",
	"Name on card": "Nombre en la tarjeta",
	"Charges will appear discreetly as tpmpsup.com": "Los cargos se mostrarán discretamente como tpmpsup.com",
	"Buy Now": "Comprar Ahora",
	"Premium package 3 Months": "Pack Premium 3 meses",

	"This purchase was declined. Please check your details and try again.": "La compra ha fallado. Por favor revisa tus detalles e inténtalo de nuevo.",
	"Your purchase was succesful.": "La compra se ha realizado con éxito.",

	"SPECIAL OFFER": "OFERTA ESPECIAL",
	"Unlock your account with our special offer": "Desbloquea tu cuenta con nuestra oferta especial",
	"Take advantage of our special offer to unlock your profile and restore all its features.": "Aprovéchate de nuestra oferta especial para desbloquear tu perfil y restaurar sus propiedades.",

	"card_number_require": "Se requiere el número de tarjeta",
	"card_number_invalid": "Número de tarjeta no válido",
	"card_name_require": "Se requiere el nombre del titular de la tarjeta",
	"card_name_invalid": "El nombre del titular de la tarjeta no es válido",
	"card_cvc_require": "Se requiere CVC",
	"card_cvc_invalid": "CVC no válido",
	"card_expiry_require": "Se requiere la fecha de vencimiento de la tarjeta",
	"card_expiry_invalid": "La fecha de caducidad de la tarjeta no es válida",

	"Private photo": "Foto privada",
	"Search for someone now": "Buscar gente ahora",
	"Edit": "Editar",

	"Locked Album": "Album cerrado",
	"Get credits to unlock all albums": "Adquiere créditos y abre todos los albums",
	"picture ::: pictures": "foto ::: fotos",
	"Open": "Abrir",

	"Receive notifications": "Recibir notificaciones",
	"I want to be notified when I receive a message": "Quiero recibir notificaciones cuando recibo un mensaje",
	"No": "No",
	"Yes": "Si",

	"logout_confirm_title": "Cerrar sesión",
	"Are you sure you want to log out?": "¿Seguro que quieres cerrar sesión?",

	"Your payment has been cancelled": "Tu pago ha sido cancelado",
	"Something went wrong, please try again in 10 minutes": "Algo ha fallado. Por favor, prueba en 10 minutos",

	"Please accept the terms and conditions to continue": "Por favor, acepta los términos y condiciones para continuar",
	"By checking this box and clicking continue I accept the": "Marcando esta casilla y haciendo click en continuar acepto los",
	"rg_terms": "Términos y Condiciones",
	"rg_privacy": "Privacidad",
	"rg_cookies": "Cookies",
	"I am aware of the use of fictitious profiles on the website and I confirm that I am of legal age.": "soy consciente del uso de perfiles ficticios en la página web y confirmo que soy Mayor de edad.",

	"Cookie": "Cookie",
	"Responsible Party": "Responsible",
	"Category": "Categoría",
	"Cookie Name": "Nombre",
	"Purpose": "Propósito",
	"Lifespan": "Duración",
	"Type of Cookie": "Tipo de Cookie",
	"Strictly necessary": "Estrictamente necesarias",
	"Functional": "Funcionales",
	"Performance": "Rendimiento",
	"Advertising": "Advertising",
	"cookie-policy": "politica-de-cookies",
	"cookie policy": "politica de cookies",

	"We Care About Your Privacy": "Nos Importa tu Privacidad",
	"This site uses its own, technical and third party cookies to improve navigation performance, analyzing visitors behaviour to help us and our marketing partners measure campaign results and personalized advertising. By clicking \"Accept All\" you are agreeing to all of these cookies.": "Este sitio utiliza cookies propias, técnicas y de terceros para mejorar el rendimiento de la navegación, analizar el comportamiento de los visitantes y ayudarnos a nosotros y a nuestros socios de marketing a medir los resultados de la campaña y publicidad personalizada. Al hacer clic en \"Aceptar todas\", aceptas todas estas cookies.",
	"Accept All": "Aceptar todas",
	"Cookies Settings": "Configuración de cookies",
	"About your privacy": "Sobre tu privacidad",
	"We use cookies for traffic balancing, session identification, analytics and marketing attribution. You can opt-in or opt-out of any or all of them from this panel." : "Utilizamos cookies para el equilibrio del tráfico, la identificación de sesiones, el análisis y la atribución de marketing. Puedes optar por participar o no participar en cualquiera de ellos o en todos ellos desde este panel.",
	"You will be able to change your set up at any time from our :cookies_policy page": "Puedes cambiar tu configuración en cualquier momento desde nuestra página de :cookies_policy",
	"Accept all": "Aceptar todas",
	"Manage Consent Preferences": "Gestionar preferencias de consentimiento",
	"Technical (Mandatory)": "Técnicas (estrictamente necesarias)",
	"Performance (Monitor website performance)": "Rendimiento (medición del rendimiento del sitio)",
	"Functional (User experience improvement)": "Funcionales (mejoran la experiencia del usuario)",
	"Advertising (Marketing measurement)": "Publicidad (mediciones de marketing)",
    "Advertising (Personalization)": "Publicidad (personalización)",
	"Save Selection": "Guardar selección",
	"Reject All": "Rechazar todas",

	"We and our partners process data to provide:": "Nosotros y nuestros socios procesamos datos para proporcionar:",
	"Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised ads and content, ad and content measurement, audience insights and product development.": "Utilice datos de geolocalización precisos. Escanee activamente las características del dispositivo para su identificación. Almacene y/o acceda a información en un dispositivo. Anuncios y contenido personalizados, medición de contenido y anuncios, información sobre la audiencia y desarrollo de productos.",
	"List of partners": "Lista de socios",
	"Cookie Policy": "Política de cookies",
	"These cookies are used in connection with the tracking of actions related to advertisements. For example, these cookies will remember that you have visited {name} and can be used to show you a personalized advertisement on another website. Please check the following links of the platforms where we promote ourselves, Google, Microsoft and Twitter. for more information.": "Estas cookies se utilizan en relación al rastreo de acciones relacionadas con los anuncios. Por ejemplo, estas cookies recordarán que has visitado {name} y se pueden para enseñarte un anuncio personalizado en otra web. Por favor chequee las siguientes urls de las plataformas donde nos promocionamos, Google, Microsoft y Twitter.",
	"Delete cookies": "Eliminar Cookies",
	"Save selection": "Guardar selección",
	"Reject all": "Rechazar todas",

	"{name} hasn't replied to you yet. Send another message!": "{name} no te ha contestado aún. ¡Envíale otro mensaje!",
	"Validate my Ice-breaker": "Validar mi Rompehielos",

	"Distance": "Distancia",
	"now": "ahora",

	"Starting  June 1, 2023 onwards, we will not process payments with PaySafeCard.": "A partir del 01 Junio 2023 en adelante, no será posible efectuar pagos con PaySafeCard.",

	"Dear User,": "Estimado usuario,",
	"Thanks for your interest in joining our website, but, in order to keep a gender balance close to 50/50 men and women, registration is not allowed for men, at the moment.": "Gracias por tu interés en unirte a nuestra web, pero en este momento el registro está cerrado para hombres ya que necesitamos encontrar un balance de géneros cercano al 50%.",
	"If you still want a date, click on the button below and try one of the best websites online.": "Si aún quieres tener una cita, haz clic en el botón y prueba uno de los mejores websites online.",
	"Try Now": "Probar Ahora",

	"Your account has been deleted.": "Tu cuenta ha sido borrada.",
	"According to your choices, our algorithms have found a better option for you.": "Nuestro algoritmo ha encontrado una opción mucho mejor para ti de acuerdo con tus elecciones.",
	"See More": "Ver Más",

	"10 credits for you!": "10 créditos para ti!",
	"Get 3 free Icebreakers": "Consigue 3 rompehielos gratis",
	"Free Icebreakers": "Rompehielos gratis",
	"Congratulations, you have received 3 Icebreakers!": "Felicidades, ¡has conseguido 3 Rompehielos!",

	"Apple pay only works with the Safari browser. Please open your Safari browser and login to your account in order to proceed with this payment.": "Apple Pay solo funciona desde el explorador web Safari. Abre Safari y accede a tu cuenta para poder continuar con el pago.",

	"No nudity": "Sin desnudos",
	"Nudity allowed": "Desnudos permitidos",

	"Edit profile": "Editar perfil",
	"Online": "En línea",
	"Step": "Paso",
	"Completed": "Completado",
	"Help": "Ayuda",
	"or Drag and drop": "o arrástrala y suéltala",

	"You will be able to send messages in": "Podrás enviar mensajes en",
	"Send": "Enviar",
	"Success": "Éxito",
	"Start a conversation with {name}": "Comenzar una conversación con {name}",
	"Failed": "Fallido",
	"Congratulations, your account has been successfully created.": "Enhorabuena, tu cuenta ha sido creada con éxito.",
	"Contact our support": "Contactar con soporte",

	"About": "Sobre",
	"Scale": "Ajustar",
	"Rotate": "Girar",

	"No favorites added": "No tienes favoritos",

    "We have sent you an email to:": "Te hemos enviado un email a:",
    "Please confirm by clicking the \"delete account\"  button in that email and your account will be permanently deleted from our system.": "Por favor confirma haciendo click en el botón \"Borrar cuenta\" en ese email y eliminaremos cuenta permanentemente.",
    "DELETE ACCOUNT": "Borrar Cuenta",

	"Didn\'t find what you were looking for?": "¿No encuentras lo que buscabas?",
	"Please send us an email and we will get back to you.": "Envíanos un email y te contestaremos lo antes posible.",
	"Or contact us using the form": "O contáctanos mediante el formulario."

}
